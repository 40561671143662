<template>
 <div class="box box_notfound">
    <div class="box_logo">
      <router-link to="/">
        <img
          src="../assets/img/logo.png"
          width="73"
          height="73"
          alt="Логотип Garmenton"
        />
      </router-link>
    </div>
    <div class="box_wrapper">
      <div class="box_title">404</div>
      <div class="box_text">Запрашиваемая страница не найдена</div>
    </div>
    <div class="box_footer">
      <div>
        <svg width="1680" height="173">
          <use xlink:href="../assets/img/icons.svg#icon-footer"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
