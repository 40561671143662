import rest from "../../restUtils";

const path = '/user';

export default {
    namespace: true,
    state: {},

    actions: {
        async getUserData(context, id) {
            try {
                let user = await rest.doGet(
                    `${path}&id=${id}`
                );
                return {
                    success: true,
                    data: user
                }
            } catch (error) {
                return {
                    success: false,
                    data: error.response.data
                }
            }
        },

        async listUsers() {
            try {
                let response = await rest.doGet(`${path}/list`);
                return {
                    success: true,
                    data: response
                };
            } catch (error) {
                return {
                    success: false,
                    data: error.response.data
                };
            }
        },
    },

    mutations: {},

    getters: {},
}