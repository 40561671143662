<template>
  <div id="app">
   <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>

import jQuery from "jquery";
const $ = jQuery;
window.$ = $;


export default {
  name: 'App',

  async created() {
    let autoAuthToken = this.$route.query.autoAuthToken;
    if (!!autoAuthToken) {
      await this.$store.dispatch('authByToken', autoAuthToken);

      let query = Object.assign({}, this.$route.query);
      delete query.autoAuthToken;
      this.$router.replace({ query });
    }
  },

  mounted() {
  }
}
</script>
<style>
@import "./css/style.scss";
@import "./css/adaptive.scss";
</style>
