<template>
    <nav class="menu menu-closed">
        <button
        class="menu_button menu_button-open"
        type="button"
        @click="openMenu()"
        title="Открыть меню"
       >
       <span class="visually-hidden">Открыть меню</span>
      </button>
      <div class="menu_wrapper menu_wrapper-hidden">
        <div>
          <div class="menu_logo">
            <img
              src="../assets/img/logo.png"
              width="36"
              height="36"
              alt="Логотип Garmenton"
            />
            <div class="menu_logo-text">Garmenton</div>
          </div>
      
          <ul class="menu_list">
            <li
              class="menu_item menu_item-users"
              :class="{ menuItemActive: activeTabs.user }"
              @click="tabChange('user')"
            >
              <svg height="16" width="16">
                <use xlink:href="../assets/img/icons.svg#icon-email"></use>
              </svg>
              <div>Пользователи</div>
            </li>
            <li
              class="menu_item menu_item-brands"
              :class="{ menuItemActive: activeTabs.brand }"
              @click="tabChange('brand')"
            >
              <svg height="22" width="22">
                <use xlink:href="../assets/img/icons.svg#icon-brands"></use>
              </svg>
              <div>Бренды</div>
            </li>
          </ul>
  
          <button
            class="menu_button vissually-hidden menu_button-close"
            type="button"
            @click="openMenu()"
            title="Закрыть меню"
          >
            <span class="visually-hidden">Закрыть меню</span>
          </button>
        </div>
        <router-link to="/" class="logout"
                     @click.native="logout"
        >Выход</router-link>
      </div>
  
    </nav>
</template>
<script>

export default {
  name: "Menu",
  props: ["tab"],
  data() {
    return {
      activeTabs: {
        user: true,
        brand: false,
      },
    }
  },

  created() {
    let tab;
    if(!this.tab) {
       tab =  this.$route.query.tab;
    } else {
      tab =  this.tab;
    }
    for (let [key, value] of Object.entries(this.activeTabs)) {
      if(key ===  tab) {
        this.activeTabs[key] = true;
        } else {
          this.activeTabs[key] = false;
        }
    }
  },
  methods: {
    openMenu() {
      const menuButtonOpen = document.querySelector(".menu_button-open");
      const menuButtonClose = document.querySelector(".menu_button-close");
      const menu = document.querySelector(".menu");
      const menuList = document.querySelector(".menu_wrapper");
      const page = document.querySelector(".box-index");
     
      if (!menuButtonOpen.classList.contains("menu_button-hidden")) {
        menuButtonOpen.classList.add("menu_button-hidden");
        menuButtonClose.classList.remove("menu_button-hidden");
        menu.classList.remove("menu-closed");
        menu.classList.add("menu-openned");
        menuList.classList.remove("menu_wrapper-hidden");
        page.classList.add("box-shadow");

      } else {
        menuButtonOpen.classList.remove("menu_button-hidden");
        menuButtonClose.classList.add("menu_button-hidden");
        menu.classList.add("menu-closed");
        menu.classList.remove("menu-openned");
        menuList.classList.add("menu_wrapper-hidden");
        page.classList.remove("box-shadow");
      }
    },

    tabChange(tabCode) {
      this.$router.push({ path: "/", query: { tab: tabCode } }).catch(()=>{});
    },

    logout() {
      this.$store.dispatch('logout');
      this.$router.push({name: 'Login'});
    },
  },
}
</script>
