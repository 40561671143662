import 'babel-polyfill'
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueMeta from 'vue-meta';
import store from "./store";
import browserDetect from "vue-browser-detect-plugin";

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});
Vue.use(browserDetect);
Vue.use({
  install: function(Vue, options){
    Vue.prototype.$jQuery = require('jquery');
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
