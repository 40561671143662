import rest from "../../restUtils";

const path = '/brand';

export default {
    namespace: true,
    state: {},

    actions: {
        async getBrand(context, id) {
            try {
                let brand = await rest.doGet(
                    `${path}?id=${id}`
                );
                return {
                    success: true,
                    data: brand
                }
            } catch (error) {
                return {
                    success: false,
                    data: error.response.data
                }
            }
        },

        async createOrUpdateBrand(context, brand) {
            try {
                let response = await rest.doPost(`${path}`, brand);
                return {
                    success: true,
                    data: response
                }
            } catch (error) {
                return {
                    success: false,
                    data: error.response.data
                }
            }
        },

      async listBrands(context, data) {
        try {
          let response = await rest.doGet(`${path}/list?role=${data.role}&page=${data.page}`);
          return {
            success: true,
            data: response
          };
        } catch (error) {
          return {
            success: false,
            data: error.response.data
          };
        }
      },

      async deleteBrand(context, id) {
        try {
          let response = await rest.doDelete(`${path}?id=${id}`);
          return {
            success: true,
            data: response
          };
        } catch (error) {
          return {
            success: false,
            data: error.response.data
          };
        }
      },

      async brandExists(context, data) {
        try {
          let response = await rest.doGet(`${path}/exists?name=${data.name}&exceptId=${!!data.id ? data.id : ""}`);
          return {
            success: true,
            data: response
          };
        } catch (error) {
          return {
            success: false,
            data: error.response.data
          };
        }
      },
    },

    mutations: {},

    getters: {},
}