<template>
  <div class="brands">
    <div class="brands_heading">
      <div
              class="brands_title"
              @click="switchTabs(false, true)"
              :class="{ brandsTitleAcive: adminBrands }"
      >
        Список брендов
      </div>
      <div
              class="brands_title"
              @click="switchTabs(true, false)"
              :class="{ brandsTitleAcive: usersBrands }"
      >
        Бренды добавленные пользователями
      </div>
      <router-link
              v-if="adminBrands === true"
              to="/add-brand"
              class="brands_add-button"
              type="button"
              tag="button"
      >Добавить бренд</router-link
      >
    </div>
    <div v-if="adminBrands">
      <div class="brands_subscription">
        <div class="brands_caption">Подписка</div>
        <form class="brands_form">
          <p>
            <label>Скидка на годовую подписку, %</label>
            <input
                    v-model="subscriptionSettings.YEAR"
                    type="text"
                    @input="formatValue('YEAR', subscriptionSettings.YEAR)"
            />
          </p>
          <p>
            <label>Скидка на бессрочную  подписку, %</label>
            <input
                    v-model="subscriptionSettings.LIFETIME"
                    type="text"
                    @input="formatValue('LIFETIME', subscriptionSettings.LIFETIME)"
            />
          </p>
          <button type="button" @click="saveSubscriptionSettings()">
            Сохранить
          </button>
        </form>
      </div>
      <div class="brands_caption">Бренды</div>
      <table class="table brands_table">
        <thead>
        <th>Наименование бренда</th>
        </thead>
        <tbody>
        <tr
                v-for="adminBrandsItem in adminBrandsList"
                v-bind:key="adminBrandsItem.id"
                @click="push(adminBrandsItem.id)"
        >
          <td>
            <div >{{ adminBrandsItem.name }}</div>
            <div>
              <button
                      class="brands_delete-button"
                      type="button"
                      @click.stop="showDeleteWindow(adminBrandsItem.id)"
              >
                <svg height="18" width="18">
                  <use xlink:href="../assets/img/icons.svg#icon-delete"></use>
                </svg>
                <span class="brands_delete-tooltip">Удалить</span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Pagination
              :currentPage="searchSettings.currentPage"
              :totalPages="searchSettings.totalPages"
              @load-page="listBrandsCreatedBy(ROLE_SUPERADMIN, $event)"
      />
      <div
              class="brands_save-message"
              v-if="checkForm"
              @click="checkForm = false"
      >
        Изменения сохранены
      </div>
    </div>
    <div v-else>
      <div class="brands_caption">Бренды</div>
      <table class="table brands_table">
        <thead>
        <th>Наименование бренда</th>
        </thead>
        <tbody>
        <tr
                v-for="usersBrandsItem in usersBrandsList"
                v-bind:key="usersBrandsItem.id"
                @click="push(usersBrandsItem.id)"
        >
          <td>
            <div>{{ usersBrandsItem.name }}</div>
            <div>
              <button
                      class="brands_delete-button"
                      type="button"
                      @click.stop="showDeleteWindow(usersBrandsItem.id)"
              >
                <svg height="18" width="18">
                  <use xlink:href="../assets/img/icons.svg#icon-delete"></use>
                </svg>
                <span class="brands_delete-tooltip">Удалить</span>
              </button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <Pagination
              :currentPage="searchSettings.currentPage"
              :totalPages="searchSettings.totalPages"
              @load-page="listBrandsCreatedBy(ROLE_USER, $event)"
      />
    </div>
    <BrandsDelete :id="toDeleteId" @deleted="deleteBrandFromList" />
  </div>
</template>

<script>
  import BrandsDelete from "../components/windows/BrandsDelete.vue";
  import constants from "../constants";
  import Pagination from "./Pagination";

  export default {
    name: "Brands",

  data() {
    return {
      adminBrandsList: [],
      usersBrandsList: [],
      subscriptionSettings: {
        YEAR: '',
        LIFETIME: ''
      },
        adminBrands: true,
        usersBrands: false,
        checkForm: false,
        pageNumber: 1,
        toDeleteId: 0,
        searchSettings: {
          role: "",
          page: 0,
          currentPage: 0,
          totalPages: 0,
          pageSize: 1,
        },
        ROLE_USER: constants.ROLE.USER,
        ROLE_SUPERADMIN: constants.ROLE.SUPERADMIN,
      };
    },
    components: { Pagination, BrandsDelete },

    async created() {
      await this.getSubscriptionSettings();
      let page = this.$route.query.page || 0;
      if (this.adminBrands) {
        await this.listBrandsCreatedBy(constants.ROLE.SUPERADMIN, Math.max(0, page - 1));
      } else {
        await this.listBrandsCreatedBy(constants.ROLE.USER, Math.max(0, page - 1));
      }
    },

    methods: {
      async getSubscriptionSettings() {
        let response = await this.$store.dispatch(
          "getSubscriptionSettings"
        );
        if (!!response.success && response.data) {
          for (let i = 0; i < response.data.list.length; i++) {
            if (response.data.list[i].code === "YEAR") {
              this.subscriptionSettings.YEAR = response.data.list[i].discount
            }
            else if (response.data.list[i].code === "LIFETIME") {
              this.subscriptionSettings.LIFETIME = response.data.list[i]?.discount
            }
          }
        }
      },

      async saveSubscriptionSettings() {
        console.log(Object.entries(this.subscriptionSettings))
        for (let [key, value] of Object.entries(this.subscriptionSettings)) {
          this.subscriptionSettings[key] = value.toString().replace(",", ".");
        }

        if (!this.subscriptionSettings.YEAR) {
          this.subscriptionSettings.YEAR = 0
        }

        if (!this.subscriptionSettings.LIFETIME) {
          this.subscriptionSettings.LIFETIME = 0
        }

        const dataBody = {
          "list": [
            {
              "code": "YEAR",
              "discount": this.subscriptionSettings.YEAR
            },
            {
              "code": "LIFETIME",
              "discount": this.subscriptionSettings.LIFETIME
            }
          ]
        }

        let response = await this.$store.dispatch(
            "createOrUpdateSubscription",
            dataBody
        );
        if (!!response && response.success) {
          this.showMessage();
        }
      },

      async listBrandsCreatedBy(role, page) {
        const query = Object.assign({}, this.$route.query);
        query.page = this.searchSettings.page;
        this.$router.replace({ query: query }).catch(() => {});

        this.searchSettings.page = page;
        this.searchSettings.role = role;
        let response = await this.$store.dispatch(
            "listBrands",
            this.searchSettings
        );
        if (!!response.success && response.data) {
          if (role === this.ROLE_USER) {
            this.usersBrandsList = response.data.content;
          } else {
            this.adminBrandsList = response.data.content;
          }
          this.searchSettings.currentPage = response.data.pageable.pageNumber;
          this.searchSettings.totalPages = response.data.totalPages;
          this.searchSettings.pageSize = response.data.pageable.pageSize;
        }
      },

      deleteBrandFromList(id) {
        this.usersBrandsList = this.usersBrandsList.filter((x) => x.id !== id);
        this.adminBrandsList = this.adminBrandsList.filter((x) => x.id !== id);
        this.toDeleteId = 0;
      },

      formatValue(name, field) {
        if (field) {
          this.subscriptionSettings[name] = field
              .replace(/[^\d,.]*/g, "")
              .replace(/([,.])[,.]+/g, "$1")
          if (!this.subscriptionSettings[name].match(/^[.,]\d{0,2}.*$/)) {
            this.subscriptionSettings[name] = this.subscriptionSettings[name].replace(/^[^\d]*(\d+([.,]\d{0,2})?).*$|^(([.,]\d{0,2})?).*$/g, "$1");
          } else {
            this.subscriptionSettings[name] = this.subscriptionSettings[name].replace(/^(([.,]\d{0,2})?).*$/g, "$1");
          }

          if (name) {
            if (Number.parseFloat(field) > 100) {
              this.subscriptionSettings[name] = 100;
            }
          }

          if (field.indexOf(".") != "-1") {
            const fieldArr = field.split(".");
            if (fieldArr[1] === "00") {
              this.subscriptionSettings[name] = fieldArr[0];
            }
          }

          // if (field.indexOf(",") != "-1") {
          //   const fieldArr = field.split(",");
          //   if (fieldArr[1] === "00") {
          //     this.subscriptionSettings[name] = fieldArr[0];
          //   }
          //   if (name === "LIFETIME") {
          //     console.log(111111)
          //     if (fieldArr[0] === "100") {
          //       this.subscriptionSettings[name] = "100";
          //     }
          //   }
          // }
        }
      },

      showMessage() {
        if (this.subscriptionSettings.YEAR || this.subscriptionSettings.LIFETIME) {
          this.checkForm = true;
          setTimeout(() => (this.checkForm = false), 2500);
        } else {
          this.checkForm = false;
        }
      },

      showDeleteWindow(id) {
        this.toDeleteId = id;
        $(".window-wrapper").addClass("window-wrapper-open");
      },

      switchTabs(userBrands, adminBrands) {
        this.usersBrands = userBrands;
        this.adminBrands = adminBrands;
        let page = this.$route.query.page || 0;
        if (userBrands) {
          this.listBrandsCreatedBy(constants.ROLE.USER, Math.max(0, page - 1));
        }
        if (adminBrands) {
          this.listBrandsCreatedBy(
              constants.ROLE.SUPERADMIN,
              Math.max(0, page - 1)
          );
        }
      },

      push(id) {
        this.$router.push({path: '/edit-brand', query: { id: id }});
      }
    },
  };
</script>
