<template>
  <div class="category category-wrapper">
    <div class="add-brand_categories">
      <div
          class="add-brand_category"
          :class="{ addBrandCategoryActive: top.active }"
          @click="checkPage(top.code)"
      >
        {{ top.name }}
      </div>
      <div
          class="add-brand_category"
          :class="{ addBrandCategoryActive: bottom.active }"
          @click="checkPage(bottom.code)"
      >
        {{ bottom.name }}
      </div>
      <div
          class="add-brand_category"
          :class="{ addBrandCategoryActive: shoes.active }"
          @click="checkPage(shoes.code)"
      >
        {{ shoes.name }}
      </div>
    </div>
    <div class="cagetories_box">
      <div class="cagetories_form-container">
        <div class="cagetories_form-wrapper">
          <table class="cagetories_form">
            <thead>
            <tr class="cagetories_row cagetories_row-head">
              <th class="categories_label">Type of clothes</th>
              <th class="categories_label">Price</th>
              <th class="categories_label">Q</th>
              <th class="categories_label">S</th>
              <th class="categories_label">C</th>
              <th class="categories_label">WR</th>
              <th class="categories_label">Sustainability</th>
              <th class="categories_label">G&nbsp;-&nbsp;rate</th>
              <th class="categories_label">FP&nbsp;-&nbsp;rate</th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(typeSetting, index) in this[currentCategory].typeSettings"
                :key="index"
                class="cagetories_row"
                :class="{ cagetoriesRowChecked: typeSetting.checked }"
            >
              <th class="cagetories_checkbox">
                <input
                    v-model="typeSetting.checked"
                    class="visually-hidden"
                    type="checkbox"
                    :id="'category-name' + index"
                    @change="typeSettingsChanged(typeSetting)"
                />
                <label class="cagetories_name" :for="'category-name' + index">{{
                    typeSetting.name
                  }}</label>
              </th>
              <td class="categories_field-wrapper">
                <label class="visually-hidden" for="price-field">Price</label>
                <input
                    v-model="typeSetting.price"
                    class="cagetories_field"
                    id="price-field"
                    type="text"
                    @input="onFieldInput(index, 'price', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].price,
                  }"
                    @focus="clearErrors('price', typeSetting)"
                    @blur="removeCommaAndDot(index, 'price')"
                />
              </td>
              <td class="categories_field-wrapper">
                <label class="visually-hidden" for="q-field">Q</label>
                <input
                    v-model="typeSetting.q"
                    class="cagetories_field"
                    id="q-field"
                    type="text"
                    @input="onFieldInput(index, 'q', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].q,
                  }"
                    @focus="clearErrors('q', typeSetting)"
                    @blur="removeCommaAndDot(index, 'q')"
                />
              </td>
              <td class="categories_field-wrapper">
                <label class="visually-hidden" for="s-field">S</label>
                <input
                    v-model="typeSetting.s"
                    class="cagetories_field"
                    id="s-field"
                    type="text"
                    @input="onFieldInput(index, 's', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].s,
                  }"
                    @focus="clearErrors('s', typeSetting)"
                    @blur="removeCommaAndDot(index, 's')"
                />
              </td>
              <td class="categories_field-wrapper">
                <label class="visually-hidden" for="c-field">C</label>
                <input
                    v-model="typeSetting.c"
                    class="cagetories_field"
                    id="c-field"
                    type="text"
                    @input="onFieldInput(index, 'c', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].c,
                  }"
                    @focus="clearErrors('c', typeSetting)"
                    @blur="removeCommaAndDot(index, 'c')"
                />
              </td>
              <td class="categories_field-wrapper">
                <label class="visually-hidden" for="wr-field">WR</label>
                <input
                    v-model="typeSetting.wr"
                    class="cagetories_field"
                    id="wr-field"
                    type="text"
                    @input="onFieldInput(index, 'wr', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].wr,
                  }"
                    @focus="clearErrors('wr', typeSetting)"
                    @blur="removeCommaAndDot(index, 'wr')"
                />
              </td>
              <td class="categories_field-wrapper">
                <label class="visually-hidden" for="sustainability-field"
                >Sustainability</label
                >
                <input
                    v-model="typeSetting.sustainability"
                    class="cagetories_field"
                    id="sustainability-field"
                    type="text"
                    @input="onFieldInput(index, 'sustainability', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType]
                        .sustainability,
                  }"
                    @focus="clearErrors('sustainability', typeSetting)"
                    @blur="removeCommaAndDot(index, 'sustainability')"
                />
              </td>
              <td class="categories_field-wrapper">
                <label
                    class="categories_label-dash visually-hidden"
                    for="g-field"
                >G&nbsp;-&nbsp;rate</label
                >
                <input
                    v-model="typeSetting.grate"
                    class="cagetories_field"
                    id="g-field"
                    type="text"
                    @input="onFieldInput(index, 'grate', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].grate,
                  }"
                    @focus="clearErrors('grate', typeSetting)"
                    @blur="removeCommaAndDot(index, 'grate')"
                />
              </td>
              <td class="categories_field-wrapper" style="width: 200px">
                <label
                    class="categories_label-dash visually-hidden"
                    for="fp-field"
                >FP&nbsp;-&nbsp;rate</label
                >
                <input
                    v-model="typeSetting.fpRate"
                    class="cagetories_field"
                    id="fp-field"
                    type="text"
                    @input="onFieldInput(index, 'fpRate', typeSetting)"
                    autocomplete="off"
                    :class="{
                    'add-brand_field-error':
                      errors[currentCategory][typeSetting.clothingType].fpRate,
                  }"
                    @focus="clearErrors('fpRate', typeSetting)"
                    @blur="removeCommaAndDot(index, 'fpRate')"
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Category",
  props: ["typeSettings", "consumerType"],

  data() {
    return {
      top: {
        name: "Top",
        code: "top",
        active: true,
        typeSettings: [],
      },
      bottom: {
        name: "Bottom",
        code: "bottom",
        active: false,
        typeSettings: [],
      },
      shoes: {
        name: "Shoes",
        code: "shoes",
        active: false,
        typeSettings: [],
      },
      currentCategory: "top",
      errors: {
        top: {},
        bottom: {},
        shoes: {},
      },
      settingCodes: [
        "price",
        "q",
        "s",
        "c",
        "wr",
        "sustainability",
        "grate",
        "fpRate",
      ],
    };
  },

  computed: {
    ...mapGetters(['topClothingType', 'bottomClothingType', 'shoesClothingType'])
  },

  mounted() {
    this.listClothingTypes();
  },

  methods: {
    checkPage(pageName) {
      var categories = [this.top, this.bottom, this.shoes];

      for (var category of categories) {
        if (category.code === pageName) {
          category.active = true;
          this.currentCategory = category.code;
        } else {
          category.active = false;
        }
      }
    },

    formatValueCategory(index, name, field) {
      if (field) {
        var container = this[this.currentCategory].typeSettings[index];

        container[name] = field
            .replace(/[^\d,.]*/g, "")
            .replace(/([,.])[,.]+/g, "$1")
            .replace(/^[^\d]*(\d+([.,]\d?)?).*$|^(([.,]\d?)?).*$/g, "$1");

        if (parseInt(field) === 10) {
          container[name] = 10;
        }

        if (Number.parseFloat(field) > 10) {
          container[name] = parseInt(container[name] / 10);
        }

        if (Number.parseFloat(field) < 1) {
          container[name] = "";
        }
      }
    },

    removeCommaAndDot(index, name) {
      var container = this[this.currentCategory].typeSettings[index];

      if (
          container[name].toString().indexOf(".") ===
          container[name].length - 1 ||
          container[name].toString().indexOf(",") === container[name].length - 1
      ) {
        container[name] = container[name]
            .toString()
            .substring(0, container[name].length - 1);
      }
    },

    async listClothingTypes() {
      let categories = [this.top, this.bottom, this.shoes];

      for (let category of categories) {
        this.errors[category.code] = {};
        let data
        switch (category.code) {
          case 'top':
            data = this.topClothingType
            break
          case 'bottom':
            data = this.bottomClothingType
            break
          case 'shoes':
            data = this.shoesClothingType
            break
        }
        for (var clothingType of data) {
          var existingSetting = this.typeSettings.filter(
              (x) => x.clothingType === clothingType.code
          );
          if (existingSetting.length > 0) {
            category.typeSettings.push(existingSetting[0]);
          } else {
            category.typeSettings.push({
              name: clothingType.name,
              clothingType: clothingType.code,
              price: "",
              q: "",
              s: "",
              c: "",
              wr: "",
              sustainability: "",
              grate: "",
              fpRate: "",
              checked: false,
            });
          }
          this.errors[category.code][clothingType.code] = {};
          for (var code of this.settingCodes) {
            this.errors[category.code][clothingType.code][code] = false;
          }
        }
      }
    },

    typeSettingsChanged(typeSetting) {
      typeSetting.consumerType = this.consumerType
      this.$emit("typeSettingsChanged", typeSetting, this.consumerType);
      if (!typeSetting.checked) {
        this.clearErrors(null, typeSetting);
        for (var code of this.settingCodes) {
          typeSetting[code] = "";
        }
      }
      this.$forceUpdate();
    },

    checkFields() {
      var categories = [this.top, this.bottom, this.shoes];

      for (var category of categories) {
        for (var typeSetting of category.typeSettings) {
          if (typeSetting.checked) {
            for (var code of this.settingCodes) {
              this.errors[category.code][typeSetting.clothingType][code] =
                  !typeSetting[code];
            }
          }
        }
      }

      this.$forceUpdate();

      for (category of categories) {
        for (typeSetting of category.typeSettings) {
          for (code of this.settingCodes) {
            if (this.errors[category.code][typeSetting.clothingType][code]) {
              return false;
            }
          }
        }
      }

      return true;
    },

    onFieldInput(index, field, typeSetting) {
      this.formatValueCategory(index, field, typeSetting[field]);
      if (typeSetting[field]) {
        typeSetting.checked = true;
        this.typeSettingsChanged(typeSetting);
      }
    },

    clearErrors(field, typeSetting) {
      if (!field) {
        for (var code of this.settingCodes) {
          this.errors[this.currentCategory][typeSetting.clothingType][
              code
              ] = false;
        }
      } else {
        this.errors[this.currentCategory][typeSetting.clothingType][
            field
            ] = false;
      }
    },
  },
};
</script>
