<template>
  <div class="box box-index">
    <Menu/>
    <Users v-if="usersChecked" class="users-page" />
    <Brands v-if="brandsChecked" class="brands-page" />
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import Users from "../components/Users.vue";
import Brands from "../components/Brands.vue";
export default {
  name: "Index",
  components: { Menu, Users, Brands },
  data() {
    return {
      brandsChecked: false,
      usersChecked: true,
    }
  },

  created() {
    if(!this.$route.query.tab) {
      const query = Object.assign({}, this.$route.query);
      query.tab = "user";
      this.$router.replace({query: query}).catch(() => {
    });
    }
      this.usersChecked = this.$route.query.tab === 'user'
      this.brandsChecked = this.$route.query.tab === 'brand'
  },
};
</script>
