import Vue from 'vue'
import Vuex from 'vuex'
import users from '@/store/modules/users.js';
import brands from '@/store/modules/brands.js';
import profile from '@/store/modules/profile.js';
import subscription from '@/store/modules/subscription.js';
import clothingType from '@/store/modules/clothingType.js';

import createPersistedState from 'vuex-persistedstate'
import * as Cookies from 'js-cookie'

Vue.use(Vuex);

export default new Vuex.Store({
  strict: true,
  modules: {
    users,
    brands,
    profile,
    subscription,
    clothingType
  },

  state: {
    restQueryCount: 0
  },

  actions: {
    restStart({commit}) {
      commit('plusQueryCount');
    },

    restFinish({commit}) {
      commit('minusQueryCount');
    }
  },

  mutations: {
    minusQueryCount: (state, payload) => {
      if (state.restQueryCount > 0)
        state.restQueryCount--;
    },

    plusQueryCount: (state, payload) => {
      state.restQueryCount++;
    },
  },

  getters: {
    restQueryIsPerforming: (state) => state.restQueryCount > 0
  },

  plugins: [createPersistedState({
    key: 'profile',
    paths: ['profile'],
    storage: {
      getItem: key => Cookies.get(key),
      setItem: (key, value) =>
          Cookies.set(key, value, {expires: 3}),
      removeItem: key => Cookies.remove(key)
    }
  }),
  ]
})
