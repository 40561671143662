import rest from "../../restUtils";

const path = '/subscription';

export default {
  namespace: true,
  state: {},

  actions: {
    async createOrUpdateSubscription(context, subscription) {
      try {
        let response = await rest.doPost(
            `${path}/setting`, subscription
        );
        return {
          success: true,
          data: response
        }
      } catch (error) {
        return {
          success: false,
          data: error.response.data
        }
      }
    },
    async getSubscriptionSettings() {
      try {
        let response = await rest.doGet(
            `/api/v1${path}/setting`
        );
        return {
          success: true,
          data: response
        }
      } catch (error) {
        return {
          success: false,
          data: error.response.data
        }
      }
    },
  },

  mutations: {},

  getters: {},
}