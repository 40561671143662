<template>
  <div class="users">
    <h1 class="users_heading">Пользователи</h1>
    <table class="table users_table">
      <thead>
        <th>Email</th>
        <th>UID</th>
        <th>Подписка до</th>
      </thead>
      <tbody>
        <tr v-for="user in users" v-bind:key="user.id">
          <td v-if="user.anonymous">Anonymously</td>
          <td v-else>{{ user.email }}</td>
          <td>{{  user.firebaseUid }}</td>
          <td v-if="user.subscriptionStatus === NO_SUBSCRIPTION">
            Без подписки
          </td>
          <td v-else>{{ getDateString(user.subscriptionEndDate) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import constants from "../constants";

export default {
  name: "Users",

  data() {
    return {
      users: [],
      NO_SUBSCRIPTION: constants.SUBSCRIPTION_STATUS.NO_SUBSCRIPTION,
    };
  },

  async created() {
    this.listUsers();
  },

  methods: {
    async listUsers() {
      let response = await this.$store.dispatch("listUsers");
      if (!!response.success && response.data) {
        this.users = response.data;
      }
    },
    getDateString(date) {
      return new Date(date).toLocaleDateString();
    },
  },
};
</script>
