import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store';
import constants from '@/constants';

import Index from './views/Index';
import AddBrand from './views/AddBrand';
import Login from "@/views/Login";
import NotFound from "@/views/NotFound";
import Construction from "@/views/Construction";
import EmailConfirmation from "@/views/EmailConfirmation"

Vue.use(Router);

const checkAuthAndRoles = async (to, from, next, roles) => {
    let auth = await store.dispatch('isAuthenticated');

    if (!auth) {
        localStorage.setItem(constants.SESSION_STORAGE_REDIRECT, to.path);
        next({name: 'Login'});
        return;
    }

    if (roles && roles.length > 0) {
        const roleCode = store.getters.currentUser.role;
        let access = roles.indexOf(roleCode) !== -1;

        if (!access) {
            if (from.params.code === '403')
                next(false);
            else
                next({name: 'Login'});
            return;
        }
    }

    next();
};

const checkNoAuth = async (next) => {
    let auth = await store.dispatch('isAuthenticated');
    if (!!auth) {
        next({name: 'Index'});
    } else {
        next();
    }
};


export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [

        {
            path: '/',
            name: 'Index',
            component: Index,
            beforeEnter: (to, from, next) => {
                checkAuthAndRoles(to, from, next, [constants.ROLE.SUPERADMIN])
            }
        },

        {
            path: '/login',
            name: 'Login',
            component: Login,
            beforeEnter: (to, from, next) => {
                checkNoAuth(next);
            }
        },
        {
            path: "/not-found",
            name: "NotFound",
            component: NotFound,
        },
        {
            path: "/construction",
            name: "Construction",
            component: Construction,
        },
        {
            path: "/email-confirmation",
            name: "EmailConfirmation",
            component: EmailConfirmation,
        },
        {
            path: "/payment-clarification",
            name: "EmailConfirmation",
            component: EmailConfirmation,
        },
        {
            path: "/add-brand",
            name: "AddBrand",
            component: AddBrand,
            beforeEnter: (to, from, next) => {
                checkAuthAndRoles(to, from, next, [constants.ROLE.SUPERADMIN])
            }
        },
        {
            path: "/edit-brand",
            name: "EditBrand",
            component: AddBrand,
            beforeEnter: (to, from, next) => {
                checkAuthAndRoles(to, from, next, [constants.ROLE.SUPERADMIN])
            }
        },
        // Должно стоять последним
        {
            path: '/*',
            redirect: '/not-found'
        }
    ]
})


