<template>
 <div class="box box_notfound box_email-confirmation">
    <div class="box_logo">
      <router-link to="/">
        <img
          src="../assets/img/logo.png"
          width="73"
          height="73"
          alt="Логотип Garmenton"
        />
      </router-link>
    </div>
    <div class="box_wrapper">
      <div class="box_text">Oops!<br>
        We're sorry! Now this link works only in mobile version. Could you please open this link and finish sign up via your phone. We' re going to fix it up and have things back to normal soon.
        <br>If you have any questions you can write to our email <a class="box_email-confirmation-link" href="mailto:garmenton@garmenton.net">garmenton@garmenton.net</a></div>
    </div>
    <div class="box_footer">
      <div>
        <svg width="1680" height="173">
          <use xlink:href="../assets/img/icons.svg#icon-footer"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EmailConfirmation",
};
</script>
