<template>
  <div class="window-wrapper">
      <div class="window window-delete-brand">
          <button class="window_close" type="button" @click="closeDeleteWindow()"></button>
          <div class="window_text">Вы уверены, что хотите удалить бренд?</div>
          <div class="window_buttons">
              <button class="window_button window_button-no" type="button" @click="closeDeleteWindow()">Нет</button>
              <button class="window_button window_button-yes" type="button" @click="deleteBrand()">Да</button>
            </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "BrandsDelete",
  props: ["id"],
  methods: {
      closeDeleteWindow() {
          $('.window-wrapper').removeClass("window-wrapper-open");
        },
    async deleteBrand() {
      let response = await this.$store.dispatch("deleteBrand", this.id);
      if (!!response.success && response.success) {
        this.$emit("deleted", this.id);
        this.closeDeleteWindow();
      }
    }
  }
}
</script>
