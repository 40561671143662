<template>
  <div class="box box-index box-categories">
    <Menu :tab="'brand'"/>
    <div class="add-brand brands">
      <div class="add-brand_heading">
        <button
            class="add-brand_back"
            type="button"
            @click="showAddBrandWindow()"
        >
          Назад
        </button>
        <h1 class="add-brand_caption">{{ pageName }}</h1>
      </div>
      <form class="add-brand_form" @submit.prevent="">
        <fieldset class="add-brand_point add-brand_point-name">
          <label class="add-brand_label">Название бренда</label>
          <input
              class="add-brand_field"
              :class="{ 'add-brand_field-error':  brandExistsError || nameEmptyError}"
              type="text"
              v-model.trim="brand.name"
              @focus="clearErrors('name')"
              @blur="checkBrandExists"
          />
          <div
              v-if="brandExistsError"
              class="add-brand_tooltip"
          >
            Бренд уже существует
          </div>
        </fieldset>

        <fieldset class="add-brand_checkboxes">
          <div class="add-brand_label">Направленность бренда</div>
          <div class="add-brand_checkboxes-list">
            <p class="add-brand_checkbox-wrapper">
              <input
                  id="man"
                  class="add-brand_checkbox visually-hidden"
                  type="radio"
                  value="man"
                  v-model="brand.consumerType"
                  @change="clearErrors('consumerType')"
              />
              <label
                  for="man"
                  class="add-brand_checkbox-label"
                  :class="{ 'add-brand_checkbox-label-error' : consumerTypesError}"
              >
                man
              </label>
            </p>
            <p class="add-brand_checkbox-wrapper">
              <input
                  id="woman"
                  class="add-brand_checkbox visually-hidden"
                  type="radio"
                  value="woman"
                  v-model="brand.consumerType"
                  @change="clearErrors('consumerType')"
              />
              <label
                  for="woman"
                  class="add-brand_checkbox-label"
                  :class="{ 'add-brand_checkbox-label-error' : consumerTypesError}"
              >
                woman
              </label>
            </p>
            <p class="add-brand_checkbox-wrapper">
              <input
                  id="kids"
                  class="add-brand_checkbox visually-hidden"
                  type="radio"
                  value="kids"
                  v-model="brand.consumerType"
                  @change="clearErrors('consumerType')"
              />
              <label
                  for="kids"
                  class="add-brand_checkbox-label"
                  :class="{ 'add-brand_checkbox-label-error' : consumerTypesError}"
              >
                kids
              </label>
            </p>
          </div>
        </fieldset>
      </form>

      <div class="add-brand_title">Категория одежды</div>
      <div v-for="consumer of consumerTypes">
        <div v-if="!fetchingSettings">
          <Category
              v-if="brand.consumerType === consumer"
              :typeSettings="clothingSettings[consumer]"
              :consumerType="consumer"
              @typeSettingsChanged="updateClothingTypeSettings"
              :ref="consumer"
          />
        </div>

      </div>
      <div class="add-brand_buttons">

        <button
            class="add-brand_button add-brand_button-cancel"
            type="button"
            @click="showAddBrandWindow()"
        >
          Отмена
        </button>

        <div class="add-brand_button-wrapper">
          <button
              class="add-brand_button add-brand_button-save"
              type="button"
              @click="saveBrand()"
          >
            Сохранить
          </button>
          <div
              v-if="getErrorNumber() === 1"
              class="add-brand_button-tooltip"
          >
            Заполните обязательную информацию о бренде! В блоке "Категория одежды" нужно ввести критерии минимум по
            одному типу одежды!
          </div>
          <div
              v-if="getErrorNumber() === 2"
              class="add-brand_button-tooltip"
          >
            Введите название бренда!
          </div>
          <div
              v-if="getErrorNumber() === 3"
              class="add-brand_button-tooltip"
          >
            Укажите направленность бренда!
          </div>
          <div
              v-if="getErrorNumber() === 4"
              class="add-brand_button-tooltip"
          >
            В блоке "Категория одежды" нужно ввести критерии минимум по одному типу одежды!
          </div>
          <div
              v-if="getErrorNumber() === 5"
              class="add-brand_button-tooltip"
          >
            Введите название бренда и укажите направленность бренда!
          </div>
          <div
              v-if="getErrorNumber() === 6"
              class="add-brand_button-tooltip"
          >
            В блоке "Категория одежды" имеются незаполненные критерии по типу одежды!
          </div>
          <div
              v-if="errorMessage"
              class="add-brand_button-tooltip"
          >
            {{ errorMessage }}
          </div>

        </div>
      </div>
    </div>

    <AddBrandWindow/>
  </div>
</template>

<script>
import Menu from "../components/Menu.vue";
import Category from "../components/Category.vue";
import AddBrandWindow from "../components/windows/AddBrandWindow.vue";

export default {
  name: "AddBrand",
  components: {Menu, Category, AddBrandWindow},
  data() {
    return {
      brand: {
        name: "",
        consumerType: 'man',
      },

      clothingSettings: {
        man: [],
        woman: [],
        kids: []
      },

      brandExistsError: false,
      consumerTypesError: false,
      nameEmptyError: false,
      settingTypesEmptyError: false,
      settingTypeNotFilledError: false,
      sending: false,
      settingCodes: ['price', 'q', 's', 'c', 'wr', 'sustainability', 'grate', 'fpRate'],
      displayError: false,
      pageName: 'Добавление бренда',
      fetchingSettings: true,
      oldBrand: null,
      consumerTypes: ['man', 'woman', 'kids'],
      errorMessage: null,
    };
  },

  methods: {
    showAddBrandWindow() {
      if (this.checkChanged()) {
        $(".window-wrapper").addClass("window-wrapper-open");
      } else {
        this.$router.push({path: '/?tab=brand'})
      }
    },

    checkChanged() {
      if (this.brand.name !== this.oldBrand.name) {
        return true;
      }
      for (let consumer of this.consumerTypes) {
        if (!this.arraysEqual(this.clothingSettings[consumer], this.oldBrand.clothingSettings[consumer])) {
          return true;
        }
      }
      return false;
    },

    arraysEqual(arr1, arr2) {
      if (arr1 === arr2) {
        return true;
      }

      if (arr1 == null || arr2 == null) {
        return false;
      }

      if (arr1.length !== arr2.length) {
        return false;
      }

      let arr1Copy = JSON.parse(JSON.stringify(arr1));
      let arr2Copy = JSON.parse(JSON.stringify(arr2));
      if (arr1Copy.length > 0 && typeof arr1Copy[0] === 'object' ||
          arr2Copy.length > 0 && typeof arr2Copy[0] === 'object') {
        arr1Copy.sort((x, y) => (x.clothingType > y.clothingType ? 1 : (x.clothingType < y.clothingType ? -1 : 0)));
        arr2Copy.sort((x, y) => (x.clothingType > y.clothingType ? 1 : (x.clothingType < y.clothingType ? -1 : 0)));
      } else {
        arr1Copy.sort();
        arr2Copy.sort();
      }

      for (let i = 0; i < arr1Copy.length; i++) {
        if (typeof arr1Copy[i] === 'object') {
          for (let code of this.settingCodes) {
            if (arr1Copy[i][code] != arr2Copy[i][code]) {
              return false;
            }
          }
        } else {
          if (arr1Copy[i] != arr2Copy[i]) {
            return false;
          }
        }
      }

      return true;
    },

    async saveBrand() {
      if (!this.sending) {
        this.sending = true;
        if (this.checkFields()) {
          this.errorMessage = null
          let body = {
            id: this.brand.id || 0,
            name: this.brand.name,
            clothingTypeSettings: [...this.clothingSettings.man, ...this.clothingSettings.woman, ...this.clothingSettings.kids]
          }

          let response = await this.$store.dispatch("createOrUpdateBrand", body);
          if (!!response.success && response.data) {
            await this.$router.push({path: '/?tab=brand'})
          } else {
            this.errorMessage = response.data.message
          }
        }
        this.sending = false;
      }
    },

    checkFields() {
      this.displayError = true;

      if (!this.brand.name) {
        this.nameEmptyError = true;
      }

      return !this.brandExistsError && !this.nameEmptyError && !this.consumerTypesError &&
          !this.settingTypesEmptyError && !this.settingTypeNotFilledError;
    },

    async checkBrandExists() {
      let response = await this.$store.dispatch("brandExists", {name: this.brand.name, id: this.$route.query.id});
      if (!!response.success) {
        this.brandExistsError = response.data;
      }
    },

    updateClothingTypeSettings(clothingType, consumerCode) {
      this.displayError = false;
      this.settingTypesEmptyError = false;
      this.settingTypeNotFilledError = false;
      this.clothingSettings[consumerCode] = this.clothingSettings[consumerCode].filter(x => x.clothingType !== clothingType.clothingType);

      if (clothingType.checked) {
        this.clothingSettings[consumerCode].push(clothingType)
      }
    },

    clearErrors(field) {
      this.displayError = false;

      if (field === 'name') {
        this.brandExistsError = false;
        this.nameEmptyError = false;
      } else if (field === 'consumerType') {
        this.consumerTypesError = false;
      }
    },

    getErrorNumber() {
      if (this.displayError && !this.brandExistsError &&
          (this.settingTypesEmptyError || this.settingTypeNotFilledError)) {
        return 1;
      }

      if (this.displayError && this.nameEmptyError && !this.brandExistsError && !this.consumerTypesError &&
          !this.settingTypesEmptyError && !this.settingTypeNotFilledError) {
        return 2;
      }

      if (this.displayError && !this.nameEmptyError && !this.brandExistsError && this.consumerTypesError &&
          !this.settingTypesEmptyError && !this.settingTypeNotFilledError) {
        return 3;
      }

      if (this.displayError && !this.nameEmptyError && !this.brandExistsError && !this.consumerTypesError &&
          this.settingTypesEmptyError) {
        return 4;
      }

      if (this.displayError && this.nameEmptyError && !this.brandExistsError && this.consumerTypesError &&
          !this.settingTypesEmptyError && !this.settingTypeNotFilledError) {
        return 5;
      }

      if (this.displayError && !this.nameEmptyError && !this.brandExistsError && !this.consumerTypesError &&
          !this.settingTypesEmptyError && this.settingTypeNotFilledError) {
        return 6;
      }

      return 0;
    },
  },

  async mounted() {
    if (this.$route.query.id) {
      this.pageName = 'Редактирование бренда';
      let response = await this.$store.dispatch("getBrand", this.$route.query.id);
      if (!!response.success && response.data) {
        this.brand.name = response.data.name;
        this.brand.id = response.data.id

        for (let setting of response.data.clothingTypeSettings) {
          setting.checked = true;
          this.clothingSettings[setting.consumerType].push(setting)
        }

      }
    }
    for (let category of ['top', 'bottom', 'shoes']) {
      await this.$store.dispatch("listClothingTypesByCategory", category)
    }
    this.fetchingSettings = false;
    this.oldBrand = {
      name: this.brand.name,
      clothingSettings: JSON.parse(JSON.stringify(this.clothingSettings))
    }
    this.brand.consumerType = 'man'
  },
};
</script>
