<template>
  <div class="window-wrapper">
      <div class="window window-add-brand">
          <button class="window_close" type="button" @click="closeDeleteWindow()"></button>
          <div class="window_text">Вы уверены, что хотите отменить изменения?</div>
          <div class="window_buttons">
              <button class="window_button window_button-no" type="button" @click="closeDeleteWindow()">Нет</button>
              <button class="window_button window_button-yes" type="button" @click="returnToBrands()">Да</button>
            </div>
      </div>
  </div>
</template>
<script>
export default {
  name: "AddBrandWindow",

  methods: {
    closeDeleteWindow() {
      $('.window-wrapper').removeClass("window-wrapper-open");
    },
    returnToBrands() {
      this.closeDeleteWindow();
      this.$router.push({path: '/?tab=brand'})
    },
  }
}
</script>
