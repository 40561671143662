<template>
    <div class="pagination" v-show="totalPages > 1">
        <div
                class="pagination__item"
                v-for="page in Array.from({length: totalPages}, (x,i) => i + 1)"
                v-bind:key="page"
                @click="loadPage(page - 1)"
                :class="{ 'pagination__item-selected': page === currentPage + 1}"
        >
            {{ page }}
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Pagination',

    props: ['currentPage', 'totalPages'],

    components: {},

    data() {
      return {}
    },

    computed: {},

    methods: {
      loadPage(page) {
        this.$emit('load-page', page);
      },
    }
  }
</script>

<style scoped>

</style>