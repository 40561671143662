<template>
  <div class="box login">
    <div class="login_logo">
      <img
        src="../assets/img/logo.png"
        width="73"
        height="73"
        alt="Логотип Garmenton"
      />
    </div>
    <div class="login_wrapper">
      <div class="login_wrapper">
      <h1 class="login_heading">System for<br />the administrator</h1>
      <form class="login_form form" autocomplete="off">
        <div class="form_heading">Вход</div>
        <div class="form_point">
          <label class="form_label" for="user-email">Email</label>
          <div class="form_field-wrapper">
            <svg height="16" width="16" :class="{ iconError: emailError }">
              <use xlink:href="../assets/img/icons.svg#icon-email"></use>
            </svg>
            <input
              v-model="userEmail"
              class="form_field field_email"
              id="user-email"
              type="email"
              autocomplete="off"
              @input="isEmailValid()"
              @keyup.enter="login"
            />
          </div>
        </div>
        <div class="form_point">
          <label class="form_label" for="user-password">Пароль</label>
          <div class="form_field-wrapper">
            <svg height="16" width="21" :class="{ iconError: passwordError }">
              <use xlink:href="../assets/img/icons.svg#icon-password"></use>
            </svg>
            <input
              v-model="userPassword"
              class="form_field field_password"
              id="user-password"
              type="password"
              autocomplete="off"
              @keyup.enter="login"
              @input="isPasswordFilled()"
            />
          </div>
        </div>
        <button
          class="form_button"
          type="submit"
          :disabled="pressed"
          @click="login"
        >
          Войти
        </button>
        <div v-if="authenticationError" class="error-msg">
          Введен неверный логин или пароль
        </div>
        <div
          v-if="
            !authenticationError &&
            !serverError &&
            (emailError || passwordError)
          "
          class="error-msg"
        >
          {{ getErrorMessage() }}
        </div>
      </form>
    </div>
    </div>
    <div class="login_footer">
      <div>
        <svg width="1680" height="173">
          <use xlink:href="../assets/img/icons.svg#icon-footer"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Login",
    data() {
      return {
        userPassword: "",
        userEmail: "",
        pressed: false,
        emailFilled: false,
        emailValid: true,
        passwordFilled: false,
        emailError: false,
        passwordError: false,
        authenticationError: false,
        serverError: false,
      };
    },

    computed: {
      isBlocked() {
        return (
            !this.emailValid ||
            !this.emailFilled ||
            this.emailError ||
            !this.passwordFilled ||
            this.passwordError
        );
      },
    },

    methods: {
      async login(event) {
        event.preventDefault();
        this.pressed = true;
        if (!this.userEmail || !this.emailValid) {
          this.emailError = true;
        }
        if (!this.userPassword && this.emailValid) {
          this.passwordError = true;
        }
        if (!!this.isBlocked) {
          this.pressed = false;
          return;
        }
        let userAuth = {
          email: this.userEmail,
          password: this.userPassword,
        };
        let error = await this.$store.dispatch("authorization", userAuth);
        if (error) {
          if (!error.response) {
            this.serverError = true;
            this.authenticationError = false;
          } else {
            this.authenticationError = true;
            this.emailError = true;
            this.passwordError = true;
            this.serverError = false;
          }
        }
        this.pressed = false;
      },

      isEmailValid() {
        this.emailError = false;
        this.passwordError = false;
        this.authenticationError = false;
        this.emailFilled = $(".field_email").val().trim() !== "";
        this.emailValid = document.getElementById("user-email").checkValidity();
      },

      isPasswordFilled() {
        this.emailError = false;
        this.passwordError = false;
        this.authenticationError = false;
        this.passwordFilled = $(".field_password").val() !== "";
      },

      getErrorMessage() {
        if (this.emailError && !this.emailValid) {
          return "Введите валидный email";
        }
        return (
            "Введите " +
            (this.emailError ? "email " : "") +
            (this.emailError && this.passwordError ? "и " : "") +
            (this.passwordError ? "пароль" : "")
        );
      },
    },
  };
</script>
