import rest from "../../restUtils";

const path = '/clothing-type';


export default {
    namespace: true,
    state: {
        topClothingType: null,
        bottomClothingType: null,
        shoesClothingType: null,
    },
    getters: {
        topClothingType: state => state.topClothingType,
        bottomClothingType: state => state.bottomClothingType,
        shoesClothingType: state => state.shoesClothingType,
    },
    mutations: {
        setTopClothingType(state, data) {
            state.topClothingType = data
        },
        setBottomClothingType(state, data) {
            state.bottomClothingType = data
        },
        setShoesClothingType(state, data) {
            state.shoesClothingType = data
        }
    },

    actions: {
        async listClothingTypesByCategory(context, categoryCode) {
            try {
                let response = await rest.doGet(`${path}/list?category=${categoryCode}`);
                switch (categoryCode) {
                    case 'top':
                        context.commit('setTopClothingType', response)
                        break
                    case 'bottom':
                        context.commit('setBottomClothingType', response)
                        break
                    case 'shoes':
                        context.commit('setShoesClothingType', response)
                        break
                }
                return {
                    success: true,
                    data: response
                };
            } catch (error) {
                return {
                    success: false,
                    data: error.response.data
                };
            }
        },
    },
}