const API_BASE_URL = process.env.VUE_APP_GLOBAL_URL;
const VUE_APP_FRONT_GLOBAL_URL = process.env.VUE_APP_FRONT_GLOBAL_URL;
const OAUTH2_REDIRECT_URI = VUE_APP_FRONT_GLOBAL_URL;

export default Object.freeze({
    SESSION_STORAGE_TOKEN: 'garmenton_token',
    SESSION_STORAGE_REDIRECT: 'garmenton_redirect',

    ACCEPT_COOKIE_NAME: 'garmenton_accept_cookie',

    ROLE: {
      SUPERADMIN: 'ROLE_SUPERADMIN',
      USER: 'ROLE_USER',
    },

    SUBSCRIPTION_STATUS: {
        NO_SUBSCRIPTION: 'NO_SUBSCRIPTION',
    },

    APP_MAIN_PAGE: '',
    APP_EMAIL: ''

});

